/* ===============#### GLOBAL STYLES ####=============== */

@import url("https://fonts.googleapis.com/css?family=Muli");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

/*  Global padding and margin */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/*  Colors */

:root {
  --kblackish: #212127;
  --kbg: #f5f5f5;
  --kred: #d01e2d;
  --kgreen: #8cc73f;
  --kblue: #043e7a;
  --kfooter: #0a0a0a;
}

/*  Basic font styles */

* {
  font-family: "Muli", sans-serif;
  color: var(--kblackish);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 48px;
  line-height: 56px;
}

h2 {
  font-size: 36px;
  line-height: 48px;
}

p {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

input.secret, input.secret:hover, input.secret:focus{
  background-color: white;
  border: white;
  outline: white;
  color:white;
  caret-color: var(--kblue);
  
}

/*  FOOTER */

.footer {
  width: 100%;
  background-color: var(--kfooter);
  height: 361px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-container {
  padding: 0px 20px;
  width: 1200px;
  display: flex;
  margin: auto;
}

.footer-container h1 {
  color: white;
  font-size: 28px;
  line-height: 34px;
  max-width: 260px;
}

.footer-container p {
  color: white;
  font-size: 16px;
  line-height: 26px;
  max-width: 280px;
}

.footer-container .adres {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 1em;
}

.footer-container > *:nth-child(2) {
  margin-left: 30px;
}

.footer-container .adres p {
  margin-bottom: none;
  max-width: 310px;
  margin-bottom: 0px;
}

.footer-container i {
  color: white;
  font-size: 30px;
  margin-right: 10px;
}

.footer-container .email {
  display: flex;
  align-items: center;
}

.footer-container .email a {
  color: white;
  text-decoration: none;
}

/* NAVBAR */

.navbar {
  width: 100%;
  background-color: white;
  height: 77px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.084);
  display: flex;
}

.navbar-container {
  padding: 0px 20px;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  align-items: center;
}

.navbar-container img {
  width: 88px;
}

.navbar-container a {
  font-size: 14px;
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  text-decoration: none;
  color: var(--kblackish);
}

/* HERO */


.hero {
  width: 100%;
  background: url("./assets/images/pexels-fauxels.jpg") no-repeat;
  height: 50vh;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;

}

.hero video{
  position: absolute; 
  object-fit: cover !important;
  left: 50%;
  z-index:-1000;
  min-width: 100%;
  width: auto;
  height: 100%;
  transform: translate(-50%, -50%);
}

.hero-container {
  padding: 0px 20px;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  /* height: 100%;
  justify-content: center;
  align-items: center; */
}

.hero-container h1 {
  padding-top: 60px;
  color: white;
  max-width: 740px;
  text-align: center;
  font-size: 34px;
  line-height: 42px;
  margin: auto;
}

.hero-container .down-arrow {
  margin: auto;
}

.hero-container .down-arrow i {
  color: white;
  font-size: 30px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  display: inline-block;
}

.hero p.disclaimer{
  color: white;
  font-family: 'Poppins';
  max-width: 400px;
  text-align: center;  
  margin-top: auto;
}

/*  INPUT SECTION */

.input-section button {
  background-color: var(--kgreen);
  width: 140px;
  border-radius: 40px;
  height: 50px;
  border: none;
  color: white;
  margin: auto;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  cursor: pointer;
}

.ant-select {
  font-size: 13px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--kblue);
}

.input-section {
  padding: 0px 20px;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.input-section p.intro {
  font-size: 18px;
  line-height: 22px;
  max-width: 600px;
  text-align: center;
  margin: auto;
}

.select-field {
  display: flex;
  flex-direction: column;
}

.select-field .label {
  margin: auto;
}

.input-section > *:nth-child(2) {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3em;
  margin-top: 30px;
  margin-bottom: 40px;
}

.input-section > *:nth-child(2) > * {
  width: 100%;
}

.input-section .label {
  display: flex;
  align-items: baseline;
}

.input-section .label p {
  margin-bottom: 4px;
}

.input-section .label i {
  font-size: 14px;
  margin-left: 2px;
  color: var(--kred);
  cursor: pointer;
}

.main-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.input-section p.extra {
  color: rgb(78, 78, 78);
  text-align: center;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  max-width: 350px;
  margin: 16px auto 0px;
}

.input-section p.extra span {
  color: var(--kblue);
  text-decoration: underline;
  cursor: pointer;
}

/*  LOADER */

.loader {
  display: flex;
  align-items: center;
  margin: auto;
  width: 100%;
  padding: 30px 0px 25px;
  background-color: var(--kbg);
}

.loader > * {
  margin: auto;
}

/*  MESSAGE MODAL */
.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-container .message-type {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.modal-container .icofont-warning {
  font-size: 28px;
  color: orange;
}

.modal-container .icofont-check {
  font-size: 28px;
  color: var(--kgreen);
}

.modal-container .modal-type-title {
  font-size: 18px;
  font-family: "Poppins";
  margin-bottom: 0px;
  font-weight: 600;
}

.message-body {
  display: flex;
  flex-direction: column;
}

.modal-container button {
  cursor: pointer;
  padding: 4px 4px 0px;
  margin-left: auto;
  font-family: "Poppins";
  border: none;
  background-color: white;
  font-size: 12px;
  color: var(--kblue);
  text-decoration: underline;
  text-transform: lowercase;
}

/* VIDEO MODAL */

.video-modal {
  width: 680px !important;
}

.ant-modal-body iframe {
  width: 664px;
  height: 372px;
}

.video-modal .ant-modal-content {
  border-radius: 4px;
  background-color: var(--kblackish);
}

.video-modal .ant-modal-body {
  padding: 8px 8px 3px 8px;
}

/* RESULTS */

.results-page {
  width: 100%;
  padding: 0px 20px;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 2rem;
  margin: 40px auto;
}

.results-page .left {
  background-color: var(--kbg);
  border-radius: 10px;
  padding: 20px 30px;
}

.results-page .left hr {
  margin: 28px 0px;
}

.results-page .results-data button {
  border: none;
  background-color: var(--kblue);
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  font-family: "Poppins";
  font-size: 12px;
  letter-spacing: 0.5px;
}

.results-page .results-data p.main {
  font-family: "Poppins";
  margin-bottom: 4px;
}

.results-page .results-data p.sub {
  font-family: "Poppins";
  margin-bottom: 0px;
  color: var(--kgreen);
}
.results-page .results-data p.sub span {
  font-family: "Poppins";
  font-weight: bold;
  margin-bottom: 0px;
  color: var(--kgreen);
}

.results-data .result-par {
  margin-top: 20px;
  margin-left: 16px;
}

.results-data table{
  margin-bottom: 8px;
}

.results-data table td{
  border: 1px solid black;
  padding: 0px 10px

}

.result-par ol p{
  margin-bottom: 0px;
}

.results-data .small p{
  margin-left: -16px;
  margin-bottom: 0px;
}

.results-data .small li{
  margin-bottom: 8px !important;
}

ol > li:nth-child(1){
  margin-bottom: 0px !important;
}

ol > li:nth-child(4){  
  margin-top: 8px !important;
}

.results-data .oob-expl {
  margin: 0px;
}

.small-header + .result-par{
  margin-top: 0px;
}

p.sub + .small-header {
  margin-top: 20px;
}

.results-data .small-header{
  font-family: 'Poppins';
  font-weight: bold;
  margin-bottom: 2px;
}

.results-page .results-data h1 {
  font-size: 26px;
  margin-bottom: 10px;
  line-height: 30px;
}

.results-page .results-data ul.roman {
  list-style-type: lower-latin;
  margin-bottom: 12px;
}

.results-page .results-data ul.roman li {
  font-weight: bold;
}

.results-page .results-data ul.roman li span {
  font-weight: normal;
}

.results-page .results-data ul {
  margin-left: 16px;
}

.results-page .results-data ol li {
  margin-bottom: 8px;
}

.results-page .results-data ul li {
  margin-bottom: 2px;
}
/* 
.results-page .results-data ul li{
  list-style-type: lower-alpha;
  margin-bottom: 4px;      
} */

.results-page .right .side-section h4 {
  font-size: 20px;
  margin-bottom: 8px;
  line-height: 22px;
}

.results-page .right .side-section a {
  color: var(--kblackish);
}

.results-page .right .side-section {
  max-width: 430px;
  margin-bottom: 60px;
}

.results-page .right .side-section {
  margin-bottom: 60px;
}

.results-page .right .side-section p {
  max-width: 430px;
}

.results-page .right .side-section button {
  border: none;
  background-color: var(--kblue);
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  font-family: "Poppins";
  font-size: 12px;
  letter-spacing: 0.5px;
}

.side-section .videos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
}

.side-section .video img {
  width: auto !important;
  height: 120px !important;
  cursor: pointer;
}

.side-section ul {
  list-style: none;
}
.side-section ul i {
  margin-right: 4px;
}

/* CONTACT FORM */

.contact-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
}

.contact-form input {
  border: solid thin gainsboro;
  border-radius: 4px;
  height: 30px;
  padding-left: 8px;
  width: 100%;
  background-color: white;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border: solid thin var(--kblue) !important;
  outline: none;
}

.contact-form input:hover,
.contact-form textarea:hover {
  border: solid thin var(--kblue) !important;
}

.contact-form textarea {
  border: solid thin gainsboro;
  border-radius: 2px;
  height: 80px;
  padding-left: 8px;
  width: 100%;
  background-color: white;
}

.contact-form > *:nth-child(2) {
  grid-column: 1/3;
}

.contact-form > *:nth-child(5) {
  grid-column: 1/3;
}

.contact-form > *:nth-child(6) {
  grid-column: 1/3;
}

.contact-form p.header {
  font-family: "Poppins";
  font-weight: 600;
  max-width: 450px;
  grid-column: 1/3;
}

.contact-form p {
  margin-bottom: 2px;
}

.contact-form button {
  background-color: var(--kgreen);
  border: none;
  padding: 12px 16px;
  border-radius: 4px;
  justify-self: end;
  color: white;
  font-weight: bold;
  font-family: "Poppins";
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 1200px) {
  .hero-container h1 {
    font-size: 26px;
    line-height: 32px;
    max-width: 640px;
  }
}

@media (max-width: 860px) {
  .input-section > *:nth-child(2) {
    gap: 1em;
  }
}

@media (max-width: 576px) {
  .navbar {
    height: 50px;
  }

  .navbar-container img {
    width: 40px;
  }

  .navbar-container {
    padding: 10px 20px;
  }

  .nav a {
    font-size: 12px;
  }

  /* VIDEO MODAL */

  .video-modal {
    width: 100vw !important;
    height: auto !important;
    padding: 0 20px;
    height: auto;
  }

  .ant-modal-body iframe {
    width: 100%;
    height: 100%;
  }

  .video-modal .ant-modal-content {
    background: none;
  }

  
  .side-section .video img {
    height: auto !important;
    width: 100% !important;
  }
  
  .hero {
    margin-bottom: 15px;
    height: 20vh;
  }
  
  .hero-container h1 {
    font-size: 20px;
    line-height: 24px;
  }
  
  .hero-container .down-arrow i {
    font-size: 20px;
  }

  .input-section p.intro {
    font-size: 16px;
  }
  
  .input-section > *:nth-child(2) {
    grid-template-columns: 1fr;
  }
  
  p.extra{
    font-size: 14px !important;
  }
  
  .results-page {
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: 0px;
  }
  
  .results-page .left{
    padding: 20px;
  }

  .input-section > *:nth-child(2) {
    margin-bottom: 20px;
  }

  .results-page h1 {
    font-size: 20px;
    line-height: 24px;
  }

  .footer {
    height: auto;
  }

  .footer-container {
    padding: 40px 20px;
    flex-direction: column;
  }

  .footer-container .adres {
    margin-top: 0px;
  }

  .footer-container > *:nth-child(2) {
    margin-left: 0px;
  }
}
